export const User = {
  key: "",
  lobbyId: "",
  playerIndex: 0,
};

export type UserData = {
  answer: number;
  isCorrect: boolean;
  isTimeout: boolean;
  startTime: number;
  time: number;
  userId: string;
};
