import { useState, useEffect } from "react";
import { database } from "../Utils/Firebase";
import { User } from "../Utils/User";
import { ref, onValue } from "firebase/database";
import { useNavigate } from "react-router-dom";
import JSON from "../data.json";
import "./WaitingRoom.css";

const WaitingRoom = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<string[]>([]);
  useEffect(() => {
    const db = database;
    onValue(ref(db, `lobby/${User.lobbyId}/users`), (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setUsers(data);
      }

      if (data.length >= JSON.maxUsersPerLobby) {
        navigate("/chat");
      }
    });
  }, [navigate]);

  return (
    <div className="waiting">
      <h1>Waiting for users to join... {users.length} / 5</h1>
    </div>
  );
};

export default WaitingRoom;
