import { child, get, ref } from "firebase/database";
import Logo from "../Images/logo.png";
import { useEffect, useState } from "react";
import { database } from "../Utils/Firebase";
import JSON from "../data.json";

const GameOver = () => {
  const [score, setScore] = useState<string>("");

  // get the list of results from the result/lobbyId
  useEffect(() => {
    const dbRef = ref(database);
    get(child(dbRef, `results/1`))
      .then((snapshot: any) => {
        if (snapshot.exists()) {
          parseRoundResults(snapshot.val());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });

  const parseRoundResults = (results: any) => {
    let roundStartTime = Number.MAX_VALUE;
    let roundEndTime = 0;
    let totalDuration = 0;

    Object.keys(results).forEach((round, index) => {
      let duration = 0;
      roundStartTime = Number.MAX_VALUE;
      // for the first round, check each users startTime.
      // the lowest startTime is when the lobby begun.
      // for each answer within a round of answers...
      Object.keys(results[round]).forEach((answer) => {
        const data = results[round][answer];

        // for each round, check each users startTime
        if (data.startTime < roundStartTime) {
          roundStartTime = data.startTime;
        }
        // for each round, check each users time
        if (data.time > roundEndTime) {
          roundEndTime = data.time;
        }

        // if timeout is set, or the answer is false, the round duration is most a round can take.
        if (data.isCorrect === "false" || data.isTimeout === "true") {
          roundEndTime = data.startTime + JSON.timePerRound;
        }
      });

      // calulate the time difference between the start and end time to work out the duration of the round
      duration = roundEndTime - roundStartTime;

      // combine all the durations to reach the total duration
      totalDuration += duration;
    });

    const maxDuration = (JSON.timePerRound * JSON.maxUsersPerLobby) / 2;
    let scorePercentage = Math.min(
      ((150 - totalDuration) / (150 - maxDuration)) * 100,
      100
    );

    if (scorePercentage < 0) {
      scorePercentage = 0;
    }

    setScore(Math.round(scorePercentage).toString());
  };

  return (
    <div className="title">
      <img src={Logo} alt="Teamony AI Logo" className="game-over-logo" />
      <h1 style={{ fontSize: "3em" }}>Game Complete</h1>
      <h2 style={{ fontSize: "2em" }}>Your team scored: {score}%</h2>
    </div>
  );
};

export default GameOver;
