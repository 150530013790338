import { child, onValue, push, set, ref } from "firebase/database";
import React, { useEffect, useRef, useState } from "react";
import { User, UserData } from "../Utils/User";
import { database } from "../Utils/Firebase";
import { useNavigate } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Filter from "bad-words";
import Template_1 from "../Images/test-template_1.png";
import Template_2 from "../Images/test-template_2.png";
import Template_3 from "../Images/test-template_3.png";
import Template_4 from "../Images/test-template_4.png";
import Template_5 from "../Images/test-template_5.png";

import Shape_1 from "../Images/shape_1.png";
import Shape_2 from "../Images/shape_2.png";
import Shape_3 from "../Images/shape_3.png";
import Shape_4 from "../Images/shape_4.png";
import Shape_5 from "../Images/shape_5.png";

import JSON from "../data.json";

import "./Chatroom.css";

const ChatRoom = () => {
  const getRandomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const startRef = useRef(Math.floor(new Date().getTime() / 1000));
  //const rotation = useRef<number>(getRandomInt(-720, 720));
  const rotation = useRef<number>(0);
  const roundTimer = useRef<NodeJS.Timeout>();
  const currentLevel = useRef<number>(0);
  const navigate = useNavigate();
  const filter = new Filter();
  const [messages, setMessages] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [confirmedOption, setConfirmedOption] = useState<number>(0);
  const [totalConfirmedOption, setTotalConfirmedOption] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>("");

  const { transcript, listening, resetTranscript } = useSpeechRecognition();

  const getShape = (level: number) => {
    switch (JSON.game[level]) {
      case 1:
        return Shape_1;
      case 2:
        return Shape_2;
      case 3:
        return Shape_3;
      case 4:
        return Shape_4;
      case 5:
        return Shape_5;
      default:
        return Shape_1;
    }
  };

  const getMask = () => {
    switch (User.playerIndex) {
      case 1:
        return Template_1;
      case 2:
        return Template_2;
      case 3:
        return Template_3;
      case 4:
        return Template_4;
      case 5:
        return Template_5;
      default:
        return Template_1;
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (inputValue.length <= 0) {
      return;
    }

    const postData = {
      author: User.key,
      message: inputValue,
    };

    const newPostKey = push(
      child(ref(database), `lobby/${User.lobbyId}/messages`)
    ).key;

    set(
      ref(database, `lobby/${User.lobbyId}/messages/${newPostKey}`),
      postData
    );

    SpeechRecognition.stopListening();
    resetTranscript();
    setInputValue("");
  };

  useEffect(() => {
    const db = database;
    onValue(ref(db, `lobby/${User.lobbyId}/messages`), (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setMessages(data);
      }
    });

    onValue(ref(database, `results/${User.lobbyId}/`), handleLevelUpdate);
    startRef.current = Math.floor(new Date().getTime() / 1000);

    clearTimeout(roundTimer.current);
    window.focus();
    roundTimer.current = setTimeout(
      () => submitOption(true),
      JSON.timePerRound
    );
  }, []);

  const handleLevelUpdate = (snapshot: any) => {
    const data = snapshot.val();
    if (data) {
      const confirmedCount = Object.keys(data[currentLevel.current]).length;
      clearTimeout(roundTimer.current);
      setTotalConfirmedOption(confirmedCount);

      if (confirmedCount >= JSON.maxUsersPerLobby) {
        currentLevel.current += 1;
        startRef.current = Math.floor(new Date().getTime() / 1000);
        if (currentLevel.current + 1 <= JSON.game.length) {
          roundTimer.current = setTimeout(
            () => submitOption(true),
            JSON.timePerRound
          );

          setSelectedOption(0);
          setConfirmedOption(0);
          setTotalConfirmedOption(0);

          (document.getElementById("shape") as HTMLImageElement).src = getShape(
            currentLevel.current
          );
        } else {
          navigate("/complete");
        }
      }
    }
  };

  const messageIds = Object.keys(messages);

  // Get the last 5 message keys
  const lastFiveMessageIds = messageIds.slice(-2);

  const handleSelectOption = (value: any) => {
    if (confirmedOption !== 0) {
      return;
    }

    setSelectedOption(value);
  };

  const handleChooseOption = (e: any) => {
    e.preventDefault();
    submitOption(false);
  };

  const submitOption = (isTimeout: boolean) => {
    if (isTimeout) {
      setSelectedOption(-1);
    }
    setConfirmedOption(selectedOption);
    clearTimeout(roundTimer.current);
    // Generate a new unique key using push
    const newPostRef = push(
      child(ref(database), `lobby/${User.lobbyId}/results/`)
    );

    // Get the key of the newly generated entry
    const newPostKey = newPostRef.key;
    let isCorrect = selectedOption === currentLevel.current;

    if (isTimeout) {
      isCorrect = false;
    }

    const data: UserData = {
      userId: User.key,
      answer: selectedOption,
      startTime: startRef.current,
      time: Math.floor(new Date().getTime() / 1000),
      isCorrect,
      isTimeout,
    };

    // Set the data at the specific location using the retrieved key
    set(
      child(
        ref(database),
        `results/${User.lobbyId}/${currentLevel.current}/${newPostKey}`
      ),
      data
    )
      .then(() => {})
      .catch((error: any) => {
        console.error(error);
      });
  };

  return (
    <div>
      <div className="bar">
        <div>
          Choices: {totalConfirmedOption} / {JSON.maxUsersPerLobby}
        </div>
        <div>
          Level: {currentLevel.current} / {JSON.game.length}
        </div>
      </div>

      <div className="container">
        <div className="top-div">
          <div id="game">
            <img
              id="shape"
              src={getShape(currentLevel.current)}
              alt="shape"
              className="shape"
              style={{ transform: `rotate(${rotation.current}deg)` }}
            />
            <div className="mask-container">
              <img src={getMask()} alt="mask" className="mask" />
            </div>
          </div>
        </div>

        <div className="chat">
          <h1>Chat History</h1>

          {lastFiveMessageIds.map((messageId) => {
            const message = messages[messageId];

            const messageText = filter.clean(message.message);
            return (
              <div key={messageId}>
                <div>Message: {messageText}</div>
                <hr />
              </div>
            );
          })}

          <h2>Submit a new Message</h2>
          <form onSubmit={handleSubmit}>
            <label>
              <input
                type="text"
                value={inputValue}
                maxLength={140}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </label>

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      <div id="options">
        <h2>Choices:</h2>
        <img
          src={Shape_1}
          alt="shape"
          className={`option ${selectedOption === 1 ? "selected-option" : ""}`}
          onClick={() => handleSelectOption(1)}
        />
        <img
          src={Shape_2}
          alt="shape"
          className={`option ${selectedOption === 2 ? "selected-option" : ""}`}
          onClick={() => handleSelectOption(2)}
        />
        <img
          src={Shape_3}
          alt="shape"
          className={`option ${selectedOption === 3 ? "selected-option" : ""}`}
          onClick={() => handleSelectOption(3)}
        />
        <img
          src={Shape_4}
          alt="shape"
          className={`option ${selectedOption === 4 ? "selected-option" : ""}`}
          onClick={() => handleSelectOption(4)}
        />
        <img
          src={Shape_5}
          alt="shape"
          className={`option ${selectedOption === 5 ? "selected-option" : ""}`}
          onClick={() => handleSelectOption(5)}
        />
        <br />
        {confirmedOption === 0 && (
          <button type="submit" onClick={(e) => handleChooseOption(e)}>
            Choose Option
          </button>
        )}

        {confirmedOption !== 0 && (
          <button type="submit" disabled>
            Option Chosen
          </button>
        )}

        <div
          className={listening ? "on" : "off"}
          onClick={() => {
            if (listening) {
              setInputValue(transcript);
              SpeechRecognition.stopListening();
              resetTranscript();
            } else {
              SpeechRecognition.startListening({ continuous: true });
            }
          }}
        />
      </div>
    </div>
  );
};

export default ChatRoom;
