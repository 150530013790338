import "./App.css";
import { Routes, Route } from "react-router-dom";
import Chatroom from "./Pages/Chatroom";
import NumberEntry from "./Pages/NumberEntry";
import WaitingRoom from "./Pages/WaitingRoom";
import GameOver from "./Pages/GameOver";

console.clear();

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="*" element={<NumberEntry />} />
        <Route path="/" element={<NumberEntry />} />
        <Route path="/waiting" element={<WaitingRoom />} />
        <Route path="/chat" element={<Chatroom />} />
        <Route path="/complete" element={<GameOver />} />
      </Routes>
    </div>
  );
}

export default App;
