import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getURLParameter } from "../Utils/getURLParameter";
import { database } from "../Utils/Firebase";
import { ref, push, child, set, get } from "firebase/database";
import { User } from "../Utils/User";
import Template_1 from "../Images/test-template_1.png";
import Template_2 from "../Images/test-template_2.png";
import Template_3 from "../Images/test-template_3.png";
import Template_4 from "../Images/test-template_4.png";
import Template_5 from "../Images/test-template_5.png";
import Shape_1 from "../Images/shape_1.png";
import Shape_2 from "../Images/shape_2.png";
import Shape_3 from "../Images/shape_3.png";
import Shape_4 from "../Images/shape_4.png";
import Shape_5 from "../Images/shape_5.png";
import Logo from "../Images/logo.png";
import "./NumberEntry.css";

const NumberEntry = () => {
  const [opennessValue, setOpennessValue] = useState<string>("");
  const [conscientiousnessValue, setConscientiousnessValue] =
    useState<string>("");
  const [extroversionValue, setExtroversionValue] = useState<string>("");
  const [agreeablenessValue, setAgreeablenessValue] = useState<string>("");
  const [neuroticismValue, setNeuroticismValue] = useState<string>("");

  const [error, setError] = useState<string>("");
  const navigate = useNavigate();
  User.lobbyId = getURLParameter("lobbyId");

  const limitOutput = (value: string): string => {
    if (value.length > 2) {
      return value.substring(0, 2);
    } else {
      return value;
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    let type: string = "";
    [
      opennessValue,
      conscientiousnessValue,
      extroversionValue,
      agreeablenessValue,
      neuroticismValue,
    ].forEach((value: string) => {
      if (value.length === 1) {
        value = "0" + value;
      }

      type += value;
    });

    if (type.length < 10) {
      return setError("Please set all the fields");
    }

    // if no lobby, make one.
    const dbRef = ref(database);
    get(child(dbRef, `lobby/${User.lobbyId}/users`))
      .then((snapshot: any) => {
        if (snapshot.exists()) {
          const users = snapshot.val();

          User.playerIndex = Object.keys(users).length + 1;

          if (User.playerIndex > 5) {
            return setError("Lobby is full");
          }

          User.key =
            push(child(ref(database), `lobby/${User.lobbyId}/users`)).key ||
            "0";

          users.push({
            id: User.key,
            type,
          });

          set(ref(database, `lobby/${User.lobbyId}`), {
            users: users,
            messages: [],
          })
            .then(() => {
              navigate("/waiting");
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          User.key =
            push(child(ref(database), `lobby/${User.lobbyId}/users`)).key ||
            "0";
          User.playerIndex = 1;

          set(ref(database, `lobby/${User.lobbyId}`), {
            users: [
              {
                id: User.key,
                type,
              },
            ],
          })
            .then(() => {
              navigate("/waiting");
            })
            .catch((error) => {
              console.error(error);
            });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderGridInput = (label: string, value: string, onChange: any) => (
    <label>
      {label}: &nbsp;
      <input
        type="number"
        max={99}
        maxLength={2}
        required
        value={value}
        onChange={(e) =>
          onChange(limitOutput(e.target.value.replace(/[^0-9]/g, "")))
        }
      />
    </label>
  );

  if (!User.lobbyId) {
    return (
      <div className="number-entry">
        <h1>Lobby ID not found</h1>
      </div>
    );
  }
  return (
    <div className="number-entry">
      <img src={Template_1} alt="mask" className="off-mask" />
      <img src={Template_2} alt="mask" className="off-mask" />
      <img src={Template_3} alt="mask" className="off-mask" />
      <img src={Template_4} alt="mask" className="off-mask" />
      <img src={Template_5} alt="mask" className="off-mask" />
      <img src={Shape_1} alt="mask" className="off-mask" />
      <img src={Shape_2} alt="mask" className="off-mask" />
      <img src={Shape_3} alt="mask" className="off-mask" />
      <img src={Shape_4} alt="mask" className="off-mask" />
      <img src={Shape_5} alt="mask" className="off-mask" />
      <img src={Logo} alt="Teamony AI Logo" className="logo" />

      <h2>Please enter your MBTI type:</h2>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridGap: "10px",
          }}
        >
          {renderGridInput("Openness", opennessValue, setOpennessValue)}
          {renderGridInput(
            "Conscientiousness",
            conscientiousnessValue,
            setConscientiousnessValue
          )}
          {renderGridInput(
            "Extroversion",
            extroversionValue,
            setExtroversionValue
          )}
          {renderGridInput(
            "Agreeableness",
            agreeablenessValue,
            setAgreeablenessValue
          )}
        </div>

        <div style={{ textAlign: "center" }}>
          {renderGridInput(
            "Neuroticism",
            neuroticismValue,
            setNeuroticismValue
          )}
        </div>
        <h2>
          This test is timed. Please do not leave this page during the test.
        </h2>
        <button className="submit" type="submit">
          Submit
        </button>
        <br />
        {error}
      </form>
    </div>
  );
};

export default NumberEntry;
